
/**
 * @name: 仓储管理-档口库存盘点
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 仓储管理-档口库存盘点
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import config from "@/config";
import {ICheckinQuery} from "@/apis/storage/checkin/types";
import {deepCopy} from "@/utils/common";
import {getGateInventoryPageApi} from "@/apis/storage/inventory";
import Big from "big.js";
import {Message} from "element-ui";
import {commitCheckApi, getCheckPriceApi, getGateCheckRecordApi} from "@/apis/storage/check";

@Component({})
export default class storageCheck extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  tableDataDetail: any = []
  // 表格总数
  tableTotal = 0
  tableTotalDetail: any = []
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // 盘点弹窗
  checkDialog: boolean = false
  // 误差库存
  errorNum: any = 0
  // 库存单位
  purchaseUnit: string = ''
  // crud配置信息
  crudOptionDetail: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: "盘点时间",
        prop: "takeStockTime",
        align: 'center',
      },
      {
        label: "盘点前数量",
        prop: "beforeStockQty",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点后数量",
        prop: "afterStockQty",
        align: 'center',
        // slot:true
      },
      {
        label: "误差数量",
        prop: "errorQty",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点价",
        prop: "stocktakingPrice",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点结果",
        prop: "totalPrice",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点说明",
        prop: "illustrate",
        align: 'center',
        overHidden: true
      },
      {
        label: "相关图片",
        prop: 'stocktakingPic',
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
      },
    ]
  }
  crudOption: ICrudOption = {
    column: [
      {
        label: "档口名称",
        prop: "gateName",
        align: 'center',

      },
      {
        label: "档口",
        prop: "gateIdList",
        align: "center",
        search: true,
        searchMultiple: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true,
        viewHide: true
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true
      },
      {
        label: "商品分类",
        prop: "sortIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/product/getSortList?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
        hide: true,
        viewHide: true
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
        viewHide: true
      },

      {
        label: "库存单位",
        prop: "purchaseUnit",
        align: 'center',
      },
      {
        label: "库存",
        prop: "allStock",
        align: 'center',
        viewHide: true
      },
    ]
  }

  validator = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback(new Error('请输入实际库存'));
    } else if (!/(^[1-9]\d{0,3}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)|(^0$)/.test(value)) {
      callback(new Error('0~9999.99'));
    } else {
      callback();
    }
  }

  rules = {
    productName: [
      {required: true, message: '请输入商品名称', trigger: 'blur'},
    ],
    afterStockQty: [
      {
        required: true, validator: this.validator, trigger: 'blur'
      }
    ]
  }

  handleCheck(row: any) {
    this.checkDialog = true
    this.modelForm = {}
    this.errorNum = 0
    this.modelForm = row
    getCheckPriceApi({
      gateId: row.gateId,
      productId: row.productId
    }).then((e: any) => {
      if (e) {
        // this.modelForm = row
        this.modelForm.stocktakingPrice = e
        // this.modelForm.afterStockQty = 0
        let num = null
        num = new Big(this.modelForm.afterStockQty || 0).minus(this.modelForm.allStock)
        this.errorNum = num.toFixed(2)
      } else {
        this.modelForm.stocktakingPrice = null
      }
    })
  }

  handleDetail(row: any, index: number) {
    this.purchaseUnit = row.purchaseUnit
    getGateCheckRecordApi({
      gateId: row.gateId,
      productId: row.productId
    }).then((e: any) => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowView(row, index)
        this.tableDataDetail = e.list
        this.tableTotalDetail = e.total
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
    const isLt = file.size / 1024 / 1024 < 0.5;
    if (!isLt) {
      this.$message.error(`上传图片大小不能超过 500KB!`);
      return false;
    }
  }

  handleChange() {
    let num = null
    num = new Big(this.modelForm.afterStockQty || 0).minus(this.modelForm.allStock)
    this.errorNum = num.toFixed(2)
  }

  // handleInput() {
  //   this.$forceUpdate()
  // }

  rowSave() {
    // @ts-ignore
    this.$refs['form'].validate((valid) => {
      if (valid) {
        this.modelForm.beforeStockQty = this.modelForm.allStock

        commitCheckApi(this.modelForm).then((e: any) => {
          if (e) {
            this.$message.success('操作成功')
            this.getList()
            this.checkDialog = false
          }
        })
      }
    })
  }


  getList() {
    const form: ICheckinQuery = deepCopy(this.queryParam);
    form.typesList = form.typesList?.toString()
    form.gateIdList = form.gateIdList?.toString()
    getGateInventoryPageApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.inventoryInquiryVoCommonPage.list;
      this.tableTotal = e.inventoryInquiryVoCommonPage.total
    })
  }

  created() {
    this.getList()
  }
}
