/**
 * @name: 仓储管理-库存查询接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 仓储管理-库存查询接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IInventoryInquiryPage} from "@/apis/storage/inventory/types";
/**
 * 分页查询
 * @returns
 */
export const getInventoryInquiryPageApi = (param:IInventoryInquiryPage) => get("/admin/inventory/getInventoryInquiryPage",param)


/**
 * 档口库存查询
 * @returns
 */
export const getGateInventoryPageApi = (param:IInventoryInquiryPage) => get("/admin/inventory/getGateInventoryPage",param)

/**
 * 档口批次库存查询
 * @returns
 */
export const getGateBatchInventoryPageApi = (param:IInventoryInquiryPage) => get("/admin/inventory/getGateBatchInventoryPage",param)

/**
 * 导出库存查询记录数据
 * @param params 查询参数
 * @returns
 */
export const exportInventoryApi = (params: IInventoryInquiryPage) => postJ("/admin/inventory/exportInventory", params,"blob")

/**
 * 导出档口库存查询记录数据
 * @param params 查询参数
 * @returns
 */
export const exportGateInventoryApi = (params: IInventoryInquiryPage) => postJ("/admin/inventory/exportGateInventory", params,"blob")

/**
 * 导出档口批次库存查询记录数据
 * @param params 查询参数
 * @returns
 */
export const exportGateBatchApi = (params: IInventoryInquiryPage) => postJ("/admin/inventory/exportGateBatch", params,"blob")
