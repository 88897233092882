/**
 * @name: 仓储管理-档口库存盘点接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 仓储管理-档口库存盘点接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IGateCheckRecordPage} from "@/apis/storage/check/types";
/**
 * 分页查询
 * @returns
 */
export const getGateCheckRecordApi = (param:IGateCheckRecordPage) => get("/admin/gateInventory/getGateCheckRecord",param)

/**
 * 获取盘点价
 * @returns
 */
export const getCheckPriceApi = (param:IGateCheckRecordPage) => get("/admin/gateInventory/getCheckPrice",param)


export const  commitCheckApi = (data:any) => postJ("/admin/gateInventory/commitCheck",data)
